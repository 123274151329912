import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const meetingApiSlice = createApi({
    reducerPath: 'meetingApiSlice',
    baseQuery: fetchBaseQuery({ 
        baseUrl: process.env.REACT_APP_API_URL + '/meeting/',
        credentials: 'include', 
    }),
    endpoints: (builder) => ({
        disconnect: builder.mutation({
            query: () => ({
                url: `disconnect`,
                method: 'GET',
            }),
        }),
        dataMeeting: builder.query({
            query: () => ({ url: 'data' }),
        }),
        changeChannelBahasa: builder.mutation({
            query: ({ ...data }) => ({
                url: `switch-bahasa`,
                method: 'POST',
                body: data
            }),
        }),
        setServerRtcId: builder.mutation({
            query: ({ ...data }) => ({
                url: `set-server-rtc-id`,
                method: 'POST',
                body: data
            }),
        }),
    }),
    refetchOnFocus: true,
    refetchOnReconnect: true,
});

export const {
    useDisconnectMutation,
    useDataMeetingQuery,
    useChangeChannelBahasaMutation,
    useSetServerRtcIdMutation,
} = meetingApiSlice;