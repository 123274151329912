import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoginInterpreterMutation } from '../features/authApiSlice.ts';
import Loader from './Loader.jsx';
import { alertError, alertNeedHelp, errFetchMessage, onlyNumber, showHidePassword } from "../helper.js";
import { useDispatch, useSelector } from "react-redux";
import { setMeetingData } from "../features/authSlice.js";
import { setAlert } from "../features/alertSlice.js";
import './../css/auth.css';
import AuthSteps from "./AuthSteps.jsx";
import { useGetSliderQuery } from "../features/sliderApiSlice.ts";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";

function LoginInterpreter() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const alert = useSelector(state => state.alert);
    const [searchParams, setSearchParams] = useSearchParams();
    const meetingCode = onlyNumber( searchParams.get('c') ?? '' );

    const { data: dataSlider, isLoading: sliderLoading, error: sliderError } = useGetSliderQuery({ 
        meeting_code: meetingCode,
        role: 'interpreter', 
    });

    useEffect( () => {
        if(!meetingCode) {
            window.location.href = '/404';
        }

        if(alert?.icon) {
            Swal.fire({
                title: alert.icon === 'success' ? 'Yeay!' : 'Ups.',
                icon: alert.icon,
                text: alert.message,
            
            }).then( () => {
                dispatch(setAlert(null));
                window.location.reload();
            });
        }
    }, []);

    const [
        loginInterpreter,
        { isLoading, isError, isSuccess, error, data }
    ] = useLoginInterpreterMutation();

    useEffect( () => {
        if(isError) {
            alertError( errFetchMessage(error) );
        }

        if(sliderError) {
            alertError( errFetchMessage(sliderError) );
        }
    
        if(isSuccess) {
            dispatch( setMeetingData({
                is_confirm: true, 
                role: 'INTERPRETER', 
                meeting_code: meetingCode,
                data: data.data 
            }) );
            
            dispatch( setAlert({icon: 'success', message: data.message}) );
            navigate('/confirm-meeting');
        }
    }, [isError, isSuccess, sliderError]);

    const handleSubmit = () => {
        if(!email) {
            alertError('The email field is required.');
            return;
        }  

        if(!password) {
            alertError('The password field is required.');
            return;
        }

        loginInterpreter({
            meeting_code: meetingCode,
            email: email,
            password: password,
        });
    }

    if(isLoading || sliderLoading) return <Loader bypassDnone />
    return (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper pt-0">
                    <div className="container-fluid">
                        <AuthSteps activeStep={0} steps={[
                            'Login', 
                            'Confirmation', 
                            'Joining'
                        ]} />
                        <hr className="hr-style"/> 

                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-9 col-lg-7 px-4">
                                <div className="card bg-card shadow mt-3">
                                    <div className="card-body p-0">
                                        <div className="row justify-content-center align-items-center">
                                            {/* CAROUSEL */}
                                            <div className="col-md-9 col-xl-7">
                                                <div id="carouselExampleIndicators" className="carousel slide"
                                                    data-ride="carousel" data-interval="false">
                                                    <ol className="carousel-indicators">
                                                        {
                                                            dataSlider?.map( (valueSlider, indexSlider) => {
                                                                return (
                                                                    <li data-target="#carouselExampleIndicators" data-slide-to={indexSlider}
                                                                        key={indexSlider} className={ indexSlider === 0 ? 'active' : '' }></li>
                                                                )
                                                            })
                                                        }
                                                    </ol>

                                                    <div className="carousel-inner">
                                                        {
                                                            dataSlider?.map( (valueSlider, indexSlider) => {
                                                                return (
                                                                    <div key={indexSlider} className={"carousel-item " + (indexSlider === 0 ? 'active' : '')}>
                                                                        <img src={valueSlider.gambar} className="d-block w-100 rounded" 
                                                                            alt={"carousel " + indexSlider} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-logo-swara col-md-8 col-lg-5">
                                                <div className="logo-swara">
                                                    <img src="/assets/images/logo-swara-1-crop.png"
                                                        className="logo-swara-1 mb-2 mt-5 " alt="Logo Swara" />
                                                </div>

                                                <div className="mb-3 form-group-detail">
                                                    <input type="email" className="form-control" placeholder="Enter Email" name="email"
                                                        value={email} onChange={ (e) => setEmail(e.target.value.trim()) } />
                                                </div>

                                                <div className="mb-3 form-group-detail">
                                                    <div className="input-group">
                                                        <input type="password" className="form-control" placeholder="Enter Your Password" name="password"
                                                            value={password} onChange={ (e) => setPassword(e.target.value.trim()) } />

                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text btn-show-password" id="showhide-password"
                                                                onClick={ (e) => showHidePassword('showhide-password') }>
                                                                <i className="fas fa-eye text-dark" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <hr className="hr-style"/>
                                                
                                                <div className="d-flex justify-content-center mt-3">
                                                    <button className="btn btn-light-blue shadow-sm" onClick={ () => handleSubmit() }>NEXT</button>
                                                </div>

                                                <div className="text-center my-3 text-primary">
                                                    <a href="#" onClick={() => alertNeedHelp()}>Need Help?</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="text-center text-dark font-weight-bold mt-3 copyright">
                                    { '©MKI ' + ( new String(new Date().getFullYear()) ) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Helmet>
                <script src="/assets/js/autoslide.js"></script>
            </Helmet>
        </>
    )
}

export default LoginInterpreter;