import { Navigate, Outlet } from "react-router-dom";
import { isAuth } from "../helper.js";

const VerifyAuth = () => {
    if(!isAuth()) {
        return ( <Navigate to='/' replace /> );
    } else {
        return ( <Outlet /> );
    }
}

export default VerifyAuth;