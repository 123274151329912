import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isConfirmMeeting: false,
        meetingData: {
            role: null,
            meeting_code: null,
            data: {}
        },
    },
    reducers: {
        setMeetingData: (state, action) => {
            const { payload } = action;

            state.isConfirmMeeting = payload.is_confirm;
            state.meetingData = {
                role: payload.is_confirm ? payload?.role : null,
                meeting_code: payload.meeting_code ?? null,
                data: payload.is_confirm ? payload.data : null,
            };
        },
    }
});

export const { setMeetingData } = authSlice.actions;
export default authSlice.reducer;