import { Helmet } from 'react-helmet';

function Loader({ bypassDnone }) {
    return (
    <>
        <Helmet>
            <link rel="stylesheet" href="/assets/css/loaderrev.css?v=5" />
        </Helmet>

        <div id="loader" className={bypassDnone ? "" : "d-none"}>
            <div className="swara-gif">
                <img src="/assets/images/logo-swara-gif.gif" alt="Gif Swara" />
            </div>
        </div>
    </>
    )
}

export default Loader;