import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMeetingData } from "../../features/authSlice.js";
import { useEffect, useState } from "react";
import { alertError, alertNeedHelp, errFetchMessage, forceRedirect, showHidePassword } from "../../helper";
import { useJoinMeetingMutation } from "../../features/authApiSlice.ts";
import Swal from "sweetalert2";
import Loader from "../Loader";
import { setAlert } from "../../features/alertSlice";
import AuthSteps from "../AuthSteps";
import { useGetSliderQuery } from "../../features/sliderApiSlice.ts";
import $ from 'jquery';
import { Helmet } from "react-helmet";

function ConfirmMeeting() {
    const isConfirmMeeting = useSelector(state => state.auth.isConfirmMeeting);
    const meetingData = useSelector(state => state.auth.meetingData);
    const alert = useSelector(state => state.alert);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [name, setName] = useState(meetingData?.data?.nama_user ?? '');
    const [meetingPassword, setMeetingPassword] = useState('');

    const { data: dataSlider, isLoading: sliderLoading, error: sliderError } = useGetSliderQuery({ 
        meeting_code: meetingData.data.meeting_code,
        role: meetingData.role, 
    });

    const back = () => {
        if(meetingData.role === 'INTERPRETER') {
            navigate('/login-interpreter?c=' + meetingData.meeting_code);
        } else {
            navigate('/');
        }
    }

    const [
        joinMeeting,
        { isLoading, isError, isSuccess, error, data }
    ] = useJoinMeetingMutation();

    useEffect( () => {
        if(isError) {
            const err = errFetchMessage(error);
            
            if( err.match(/connected to a meeting/gi) !== null ) {
                navigate('/meeting');
            } else {
                alertError( errFetchMessage(error) );
            }
        }

        if(sliderError) {
            alertError( errFetchMessage(sliderError) );
        }
    
        if(isSuccess) {
            Swal.fire({
                title: 'Yeay!',
                icon: 'success',
                text: data.message,
            }).then( () => {
                window.localStorage.setItem('NAMA', data.data.nama);
                dispatch(setMeetingData({ is_confirm: false }));
                navigate('/meeting');
            });
        }
    }, [isError, isSuccess, sliderError]);

    useEffect( () => {        
        if(alert?.icon) {
            Swal.fire({
                title: alert.icon === 'success' ? 'Yeay!' : 'Ups.',
                icon: alert.icon,
                text: alert.message,
            
            }).then( () => {
                dispatch(setAlert(null));
            });
        }
    }, []);

    if(!isConfirmMeeting && !isSuccess) {
        return forceRedirect('/');
    }

    const handleSubmit = () => {
        if( meetingData.role === 'USER' && !name ) {
            alertError('The name field is required.');
            return;
        }

        if( meetingData.data.meeting_tipe === 'private' && !meetingPassword ) {
            alertError('The meeting password field is required.');
            return;
        }

        joinMeeting({
            meeting_code: meetingData.data.meeting_code,
            join_token: meetingData.data.join_token,
            nama_user: (name ?? '').trim(),
            meeting_password: (meetingPassword ?? '').trim(),
        });
    }

    if(isLoading || sliderLoading) return <Loader bypassDnone />
    return (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper pt-0">
                    <div className="container-fluid">
                        <AuthSteps activeStep={1} steps={[ 
                            meetingData.role === 'INTERPRETER' ? 'Login' : 'Scan QR', 
                            'Confirmation', 
                            'Joining' 
                        ]} />  
                        <hr className="hr-style" />

                        <div className="row justify-content-center align-items-center ">
                            <div className="col-md-9 col-lg-8 px-4">
                                <div className="card bg-card mt-3 shadow">
                                    <div className="card-body p-0">
                                        <div className="row justify-content-center align-items-center">
                                            {/* CAROUSEL */}
                                            <div className="col-md-9 col-xl-7">
                                                <div id="carouselExampleIndicators" className="carousel slide"
                                                    data-ride="carousel">
                                                    <ol className="carousel-indicators">
                                                        {
                                                            dataSlider?.map( (valueSlider, indexSlider) => {
                                                                return (
                                                                    <li data-target="#carouselExampleIndicators" data-slide-to={indexSlider}
                                                                        key={indexSlider} className={ indexSlider === 0 ? 'active' : '' }></li>
                                                                )
                                                            })
                                                        }
                                                    </ol>

                                                    <div className="carousel-inner">
                                                        {
                                                            dataSlider?.map( (valueSlider, indexSlider) => {
                                                                return (
                                                                    <div key={indexSlider} className={"carousel-item " + (indexSlider === 0 ? 'active' : '')}>
                                                                        <img src={valueSlider.gambar} className="d-block w-100 rounded" 
                                                                            alt={"carousel " + indexSlider} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            {/* FORM INPUT */}
                                            <div className="col-md-8 col-xl-5">
                                                <div className="logo-swara col-logo-swara">
                                                    <img src="/assets/images/logo-swara-1-crop.png"
                                                        className="logo-swara-1 mt-5 mb-2" alt="Logo Swara" />
                                                </div>

                                                <div className="d-flex justify-content-center">
                                                    <table id="table-detail-meeting" border="0" className="my-2">
                                                        <tbody>
                                                            <tr>
                                                                <th className="bg-text-confirm">Meeting Name</th>
                                                                <th className="spacer bg-text-confirm">:</th>
                                                                <td className="bg-text-confirm">{meetingData.data.judul_meeting}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="bg-text-confirm">Duration</th>
                                                                <th className="spacer bg-text-confirm">:</th>
                                                                <td className="bg-text-confirm">{meetingData.data.meeting_durasi}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="bg-text-confirm">Location</th>
                                                                <th className="spacer bg-text-confirm">:</th>
                                                                <td className="bg-text-confirm">{meetingData.data.location_name}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                {
                                                    meetingData.role === 'USER' && 
                                                    <div className="mb-3 form-group-detail">
                                                        <input type="text" className="form-control" placeholder="Enter Your Name" name="name"
                                                            value={ name } onChange={ (e) => setName(e.target.value) } />
                                                    </div>
                                                }

                                                {
                                                    meetingData?.data?.meeting_tipe === 'private' &&
                                                    <div className="mb-3 form-group-detail">
                                                        <div className="input-group">
                                                            <input type="password" className="form-control" placeholder="Enter Meeting Password" 
                                                                name="meeting_password" onChange={ (e) => setMeetingPassword(e.target.value) } />

                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text btn-show-password" id="showhide-meeting_password"
                                                                    onClick={ (e) => showHidePassword('showhide-meeting_password') }>
                                                                    <i className="fas fa-eye text-dark" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <hr className="hr-style"/>
                                                <div className="d-flex justify-content-center">
                                                    <a className="btn btn-dark-red shadow-sm mr-4 ml-4" onClick={ () => back() }>BACK</a>
                                                    <button className="btn btn-light-blue shadow-sm mr-4" onClick={ () => handleSubmit() }>NEXT</button>
                                                </div>

                                                <div className="text-center my-3 text-primary">
                                                    <a href="#" onClick={() => alertNeedHelp()}>Need Help?</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="text-center text-dark font-weight-bold mt-3">
                                    { '©MKI ' + ( new String(new Date().getFullYear()) ) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Helmet>
                <script src="/assets/js/autoslide.js"></script>
            </Helmet>
        </>
    )
}


export default ConfirmMeeting;