import { Helmet } from 'react-helmet';

function NotFound() {
    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="/assets/css/notFound.css" />
            </Helmet>

            <h1 id='title'>404</h1>
            <p id='message'>Page Not Found</p>
        </>
    );
}

export default NotFound;