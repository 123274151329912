function ThankYou() {
    return (
        <>
            <div className="container">
                <div className="row justify-content-center align-items-center p-0 mt-4">
                    <div className="col">
                        <h1 className="text-center text-light-blue font-weight-bold mb-4">Terima Kasih</h1>
                        <h3 className="text-center text-light-blue ">telah menggunakan</h3>
                        <div id="swara-gift">
                            <img src="/assets/images/logo-swara-gif.gif" className=" swara-gift p-0" alt="Gif Swara" />
                        </div>
                        <div className="d-flex justify-content-center">
                            <a href="/" className="btn btn-dark-red tex-center shadow">SCAN QR</a>
                        </div>
                        <div className="text-center text-dark-blue font-weight-bold mt-3">©MKI2023</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThankYou;