import { Navigate, Outlet } from "react-router-dom";
import { isAuth } from "../helper.js";

const CheckAuth = () => {
    if(isAuth()) {
        return ( <Navigate to='/meeting' replace /> );
    } else {
        return ( <Outlet /> );
    }
}

export default CheckAuth;