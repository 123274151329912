import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { alertError, errFetchMessage, generateDeviceId, showLoader } from "../../helper";
import Loader from "../Loader";
import { useScanQrMutation } from "../../features/authApiSlice.ts";
import { useDispatch } from "react-redux";
import { setAlert } from "../../features/alertSlice";
import { setMeetingData } from "../../features/authSlice";

function DirectScanQr() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { qr } = useParams();
    const DEVICE_ID = generateDeviceId();

    const [scanQr] = useScanQrMutation();
    useEffect( () => {
        showLoader();
        const errorGeolocation = async () => {
            await alertError('Your location failed to get, make sure you give location permission to this site.');
            window.location.href = '/';
        }

        window.navigator.geolocation.getCurrentPosition((position) => {
            scanQr({
                device_id: DEVICE_ID,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                qr_value: qr,

            }, false)
            .unwrap().then( ({ message, data }) => {
                dispatch( setMeetingData({
                    is_confirm: true, 
                    role: 'USER', 
                    data: data 
                }) );
    
                dispatch( setAlert({icon: 'success', message: message}) );
                navigate('/confirm-meeting');
    
            }).catch( (error) => {
                dispatch( setAlert({icon: 'error', message: errFetchMessage(error)}) );
                navigate('/');
            });
        }, errorGeolocation);
    }, []);

    return (
        <Loader />
    );
}

export default DirectScanQr;