import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        icon: null,
        message: null,
    },
    reducers: {
        setAlert: (state, action) => {
            const { payload } = action;
            state.icon = payload?.icon;
            state.message = payload?.message;
        }
    }
});

export const { setAlert } = alertSlice.actions;
export default alertSlice.reducer;