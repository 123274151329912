import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import { UUIDv4 } from "uuid-v4-validator";
import moment from 'moment-timezone';

export const forceRedirect = (to) => {
    return (
        <> <Navigate to={to} replace /> </>
    );
}

export const errFetchMessage = (err) => {
    if(err instanceof TypeError) return err.message;
    if(err?.status === 'FETCH_ERROR') return err?.error;
    
    return err?.data?.error;
}

export const alertError = (message) => {
    return Swal.fire({
        title: 'Ups.',
        icon: 'error',
        text: message
    });
}

export const isAuth = () => {
    return Cookies.get('_t') !== undefined;
}

export const generateDeviceId = () => {
    const { localStorage } = window;
    const currentDeviceId = localStorage.getItem('_d_id');
    if( UUIDv4.validate(currentDeviceId) ) return currentDeviceId;

    const deviceId = UUIDv4.generate();
    localStorage.setItem('_d_id', deviceId);
    return deviceId;
}

export const alertConfirm = (message) => {
    return Swal.fire({
        title: 'Are you sure?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',    
    });
}

export const fixDateStrTz = (dateStr) => {
    const momentDateStr = moment(dateStr);
    const utcOffset = momentDateStr.parseZone().utcOffset();
    return momentDateStr.subtract(utcOffset, 'm');
}

export const initMoment = (dateStr = undefined) => {
    if(typeof dateStr == 'string') dateStr = new Date(dateStr);
    return moment( fixDateStrTz(dateStr) ).tz(process.env.REACT_APP_TZ);
}

export const fixNumString = (num) => {
    return num < 10 ? '0' + num : num;
}

export const onlyNumber = (str) => {
    return str.replace(/[^0-9]/g, '');
}

export const loadWordingsConfig = () => {
    const config = window.localStorage.getItem('WORDINGS_CONFIG');
    return JSON.parse(config);
}

export const alertNeedHelp = () => {
    Swal.fire({
        title: 'Need Help?',
        icon: 'info',
        text: 'You can contact us through one of the platforms available below.',
        confirmButtonText: 'EMAIL',
        confirmButtonColor: '#FF4747',
        showCancelButton: true,
        cancelButtonText: 'WHATSAPP',
        focusConfirm: false,
        cancelButtonColor: '#57B657',
        showCloseButton: true,

    }).then( ({ isConfirmed, isDismissed, dismiss }) => {
        if(isDismissed && dismiss !== 'cancel') return;
        
        if(isConfirmed) {
            // email
            window.open('mailto:' + process.env.REACT_APP_EMAIL);
        } else {
            // whatsapp
            window.open(process.env.REACT_APP_LINK_WA);
        }
    });
}

export const showLoader = () => {
    if(!document.getElementById('loader')) return;
    document.getElementById('loader').classList.remove('d-none');
}

export const hideLoader = () => {
    if(!document.getElementById('loader')) return;
    document.getElementById('loader').classList.add('d-none');
}

export const showHidePassword = (elId) => {
    const el = window.document.getElementById(elId);
    const i = el.querySelector('i');
    const input = window.document.querySelector(`[name=${ elId.replace(/showhide\-/gi, '') }]`);

    if( input.getAttribute('type') === 'password' ) {
        input.setAttribute('type', 'text');
        i.classList.remove('fa-eye');
        i.classList.add('fa-eye-slash');
    } else {
        input.setAttribute('type', 'password');
        i.classList.remove('fa-eye-slash');
        i.classList.add('fa-eye');
    }
}