import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const sliderApiSlice = createApi({
    reducerPath: 'sliderApiSlice',
    baseQuery: fetchBaseQuery({ 
        baseUrl: process.env.REACT_APP_API_URL + '/slider',
        credentials: 'include', 
    }),
    endpoints: (builder) => ({
        getSlider: builder.query({
            query: (data) => {
                const { meeting_code, page, role } = data;

                return {
                    url: ``,
                    method: 'GET',
                    params: { meeting_code, page, role },
                }
            },
        }),
    })
});

export const {
    useGetSliderQuery,
} = sliderApiSlice;