import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ScanQr from './components/ScanQr.jsx';
import ConfirmMeeting from './components/login/ConfirmMeeting.jsx';
import MeetingRoom from './components/MeetingRoom.jsx';
import { Provider } from 'react-redux';
import store from './store.js';
import LoginInterpreter from './components/LoginInterpreter.jsx';
import CheckAuth from './middleware/CheckAuth.js';
import VerifyAuth from './middleware/VerifyAuth.js';
import DisconnectMeeting from './components/DisconnectMeeting.jsx';
import NotFound from './components/NotFound.jsx';
import DirectScanQr from './components/login/DirectScanQr.jsx';
import ThankYou from './components/ThankYou.jsx';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Helmet>
        <link rel="stylesheet" href="/assets/css/vertical-layout-light/style.css?v=4" />
        <link href="/assets/vendors/font-awesome/css/font-awesome.min.css?v=3" rel="stylesheet" type="text/css" />
        <link href="/assets/vendors/fontawesome-free/css/all.min.css?v=3" rel="stylesheet" type="text/css" />
        <link rel="stylesheet" href="'/assets/js/7fdd60d3a4.js?v=3" />
        <link rel="stylesheet" href="/assets/css/style.css?v=4" />
      </Helmet>

      <BrowserRouter>
        <Routes>
          <Route element={<CheckAuth />}>
            <Route index element={< ScanQr />}/>
            <Route path='login-interpreter' element={< LoginInterpreter />}/>
            <Route path='confirm-meeting' element={< ConfirmMeeting />}/>
            <Route path='direct-scan-qr/:qr' element={< DirectScanQr />}/>
          </Route>

          <Route element={<VerifyAuth />}>
            <Route path='meeting' element={< MeetingRoom />}/>
            <Route path='disconnect-meeting' element={< DisconnectMeeting />}/>
          </Route>

          {/* REV */}
          <Route path='thankyou' element={< ThankYou />}/>
          <Route path='*' element={ <NotFound /> } />
        </Routes>
      </BrowserRouter>

      <Helmet>
        <script src="/assets/vendors/js/vendor.bundle.base.js"></script>
        <script src="/assets/js/7fdd60d3a4.js"></script>
        <script src="/assets/js/peerjs.min.js"></script>
        {/* <script src="/assets/js/wordings-config.js" type="module"></script> */}
      </Helmet>
      
    </React.StrictMode>
  </Provider>
);
