import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApiSlice = createApi({
    reducerPath: 'authApiSlice',
    baseQuery: fetchBaseQuery({ 
        baseUrl: process.env.REACT_APP_API_URL + '/auth/',
        credentials: 'include', 
    }),
    endpoints: (builder) => ({
        scanQr: builder.mutation({
            query: ({ ...data }) => ({
                url: `scan-qr`,
                method: 'POST',
                body: data
            }),
        }),
        
        loginInterpreter: builder.mutation({
            query: ({ ...data }) => ({
                url: `login-interpreter`,
                method: 'POST',
                body: data
            }),
        }),

        joinMeeting: builder.mutation({
            query: ({ ...data }) => ({
                url: `join-meeting`,
                method: 'POST',
                body: data
            }),
        }),
    }),
});

export const { 
    useScanQrMutation, 
    useLoginInterpreterMutation, 
    useJoinMeetingMutation,
} = authApiSlice;