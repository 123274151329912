import { useDispatch, useSelector } from "react-redux";
import { useScanQrMutation } from "../features/authApiSlice.ts";
import { Html5QrcodeScanType, Html5Qrcode } from "html5-qrcode";
import { useEffect, useInsertionEffect } from "react";
import { setMeetingData } from '../features/authSlice';
import { useNavigate } from "react-router-dom";
import { alertError, alertNeedHelp, errFetchMessage, generateDeviceId } from "../helper";
import Loader from "./Loader";
import { setAlert } from '../features/alertSlice.js';
import './../css/auth.css';
import Swal from "sweetalert2";

function ScanQr(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const DEVICE_ID = generateDeviceId();
    let locationCoords;

    const getLocation = (position) => {
        locationCoords = { 
            latitude: position.coords.latitude, 
            longitude: position.coords.longitude, 
        };
    }

    const errorGeolocation = async () => {
        await alertError('Your location failed to get, make sure you give location permission to this site.');
        window.location.href = '/';
    }

    const alert = useSelector(state => state.alert);
    window.navigator.geolocation.getCurrentPosition(getLocation, errorGeolocation);
    const [scanQr, { isLoading, isError, isSuccess, error, data }] = useScanQrMutation();

    useEffect( () => {
        if(isError) {
            alertError( errFetchMessage(error) ).then( () => {
                window.location.reload();
            });
        }
    
        if(isSuccess) {
            dispatch( setMeetingData({
                is_confirm: true, 
                role: 'USER', 
                data: data.data 
            }) );
            
            dispatch( setAlert({icon: 'success', message: data.message}) );
            navigate('/confirm-meeting');
        }
    }, [isError, isSuccess]);

    useEffect( () => {        
        if(alert?.icon) {
            Swal.fire({
                title: alert.icon === 'success' ? 'Yeay!' : 'Ups.',
                icon: alert.icon,
                text: alert.message,
            
            }).then( () => {
                dispatch(setAlert(null));
                window.location.reload();
            });
        }
    }, []);

    let scannerLoaded = 0;
    useEffect( () => {
        if(scannerLoaded > 0) return;
        scannerLoaded++;

        const handleQrValue = (qrValue) => {
            qrScanner.pause();
            if(!locationCoords) {
                errorGeolocation();
                return;
            }

            scanQr({
                device_id: DEVICE_ID,
                latitude: locationCoords.latitude,
                longitude: locationCoords.longitude,
                qr_value: qrValue,
            }, false);
        }
    
        const qrScanner = new Html5Qrcode('qrcode-scanner');
        const scannerConfig = { 
            supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA], 
        };

        const startScanner = async () => {
            try {
                await Html5Qrcode.getCameras();
                qrScanner.start({facingMode: 'environment'}, scannerConfig, handleQrValue);

            } catch(e) {
                if(Swal.isVisible()) return;
                alertError('Failing to access the camera, please make sure you have granted your camera access.').then( () => {
                    window.location.href = '/';
                });
            }
        }

        startScanner();
    }, []);

    if(isLoading) return <Loader bypassDnone />
    return (
        <>
            <div className="bg-header fixed-top">
                <img src="/assets/images/logo-swara-2-crop.png" id="app-logo-swara" alt="Logo Aplikasi"
                    className="d-none d-md-block rounded" />
                <div className="d-flex d-md-none align-items-center justify-content-center">
                    <img src="/assets/images/logo-swara-2-crop.png" className="app-logo-swara-mobile"
                        alt="Logo Aplikasi Swara" width="160" />
                </div>
            </div>

            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center h-100-desktop">
                            <div className="col-md-8 col-lg-4 col-sm-12 col-12">
                                <h3 className="text-center font-weight-bold mb-4 text-dark-blue mt-5">
                                    QR CODE SCAN
                                </h3>
                                <div className="card mt-3 text-center bg-scan shadow">
                                    <div className="card-body text-center">
                                        <div id="qrcode-scanner"></div>
                                    </div>
                                </div>
                                <div className="text-center mt-3 text-dark">
                                    <a href="#" onClick={() => alertNeedHelp()}>Need Help?</a>
                                </div>
                                <div className="text-center text-dark-blue mt-2 font-weight-bold">
                                    { '©MKI ' + ( new String(new Date().getFullYear()) ) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScanQr;