import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice.js";
import alertReducer from "./features/alertSlice.js";
import { authApiSlice } from "./features/authApiSlice.ts";
import { meetingApiSlice } from "./features/meetingApiSlice.ts";
import { sliderApiSlice } from "./features/sliderApiSlice.ts";

export default configureStore({
    reducer: {
        auth: authReducer,
        alert: alertReducer,
        [authApiSlice.reducerPath]: authApiSlice.reducer,
        [meetingApiSlice.reducerPath]: meetingApiSlice.reducer,
        [sliderApiSlice.reducerPath]: sliderApiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authApiSlice.middleware)
            .concat(meetingApiSlice.middleware)
            .concat(sliderApiSlice.middleware)
});