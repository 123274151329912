import { alertError, errFetchMessage, alertConfirm, initMoment, fixNumString, showLoader, hideLoader } from "../helper.js";
import { useEffect, useState } from 'react';
import { useChangeChannelBahasaMutation, useDataMeetingQuery } from
"../features/meetingApiSlice.ts";
import Loader from './Loader.jsx';
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { setAlert } from "../features/alertSlice.js";
import { now } from 'moment';
import moment from "moment";
import { UUIDv4 } from "uuid-v4-validator";
import { io } from "socket.io-client";
import Cookies from 'js-cookie';
import './../css/meetingRoom.css';

function MeetingRoom() {
    const {data: meetingData, isLoading: meetingIsLoading, isError: meetingIsError, error: meetingError, isSuccess:
    meetingLoaded, refetch: refreshMeetingData} = useDataMeetingQuery();
    const dispatch = useDispatch();

    const [connectedUser, setConnectedUser] = useState([]);
    const [inputAudio, setInputAudio] = useState([]);
    const [socket, setSocket] = useState(null);
    const [myPeer, setMyPeer] = useState(undefined);
    const [isConnect, setIsConnect] = useState(undefined);

    const [currentBahasa, setCurrentBahasa] = useState({
        idChannelBahasa: null,
        rtcId: null,
        id_interpreter: null,
        nama_interpreter: null,
        foto_interpreter: null,
    });

    const platform = navigator.userAgentData?.platform;


    const NAMA = window.localStorage.getItem('NAMA');

    useEffect(() => {
        const handleVisibilityChange = () => {
          if (document.visibilityState === 'visible') {
            // The page is in focus

            if(platform === 'Android' || platform === 'Windows'){
                console.log('handleVisibilityChange Android / Windows');
                
            }else{
                console.log('handleVisibilityChange IOS');
                Swal.fire({ 
                    title: 'Information' , 
                    icon: 'info', 
                    text: 'Reconnect Server Audio',
                    allowOutsideClick: false, 
                    allowEscapeKey: false
    
                }).then( () => {
                    console.log('Page is now visible');
                    muteAudio(true);
                    muteAudio(false);
                });
            }
           
            console.log('Page is now visible = ');
    

            
          } 
        //   else {
        //     // The page is out of focus (e.g., user switched to another tab or minimized the browser)
        //     console.log('Page is now hidden');
        //   }
        };
    
        // Add event listener for the visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        // Clean up the event listener on component unmount
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }, []);

    // update callback when state updated
    const onReplyUpdateServerRtc = (dataChannelInterpreter) => {
        console.log('fungsi onReplyUpdateServerRtc = '+meetingData.role);
        // listener event REPLY-UPDATE-SERVER-RTC-ID
        if(meetingData.role === 'INTERPRETER') {
            if(
                dataChannelInterpreter.id_channel_bahasa == currentBahasa.idChannelBahasa && 
                dataChannelInterpreter.id_interpreter != meetingData.id_interpreter &&
                dataChannelInterpreter.server_rtc_id &&
                !window.MUTE_STATUS
            ) {
                console.log('REPLY-UPDATE-SERVER-RTC-ID!!');
                muteAudio(true);
            }

        } else if(meetingData.role === 'USER') {
            console.log('REPLY-UPDATE-SERVER-RTC-ID currentBahasa.idChannelBahasa = '+currentBahasa.idChannelBahasa);
            console.log('REPLY-UPDATE-SERVER-RTC-ID dataChannelInterpreter.id_channel_bahasa = '+dataChannelInterpreter.id_channel_bahasa);
            if(currentBahasa.idChannelBahasa !== dataChannelInterpreter.id_channel_bahasa) return;
            console.log('REPLY-UPDATE-SERVER-RTC-ID!!');

            if( !dataChannelInterpreter.server_rtc_id ) {
                console.log('REPLY-UPDATE-SERVER-RTC-ID 1');
                // kalo notif yg dikirim berupa "mute"
                // if(meetingData?.role === 'USER' && document.visibilityState !== 'visible') {
                //     console.log('REPLY-UPDATE-SERVER-RTC-ID 2');
                //     //kenapa di remove ketika off screen
                //     localStorage.removeItem('last_dataChannelInterpreter');
                // }
                // console.log('REPLY-UPDATE-SERVER-RTC-ID 3');
                destroyServerConnection('INTERPRETER_HAS_MUTE');

            } else {
                // setup koneksi untuk user
                // console.log('REPLY-UPDATE-SERVER-RTC-ID 4 = '+dataChannelInterpreter.server_rtc_id);
                // console.log('REPLY-UPDATE-SERVER-RTC-ID 4 = '+dataChannelInterpreter.id_interpreter);
                // if(meetingData?.role === 'USER' && document.visibilityState !== 'visible') {
                //     console.log('REPLY-UPDATE-SERVER-RTC-ID 5');
                //     if(window.dataConnection) {
                //         console.log('REPLY-UPDATE-SERVER-RTC-ID 6');
                //         setInterpreterProfile(dataChannelInterpreter);
                //         setStatusBar('danger');
                //         setMuteStatusBar(false);

                //         if(window.audioPlayer) {
                //             console.log('REPLY-UPDATE-SERVER-RTC-ID 7');
                //             window.audioPlayer.srcObject = null;
                //             window.audioPlayer.autoplay = false;
                //             window.audioPlayer.muted = true;
                //             window.audioPlayer = undefined;
                //         }
                //     }
                //     console.log('REPLY-UPDATE-SERVER-RTC-ID 8');
                //     localStorage.setItem('last_dataChannelInterpreter', JSON.stringify(dataChannelInterpreter));
                    // return;
                // }

                if(window.dataConnection) {
                    console.log('REPLY-UPDATE-SERVER-RTC-ID 9');
                    window.FORCE_CLOSE_DATACONNECTION = true;
                    window.dataConnection.close();
                }

                if(window.mediaConnection) {
                    console.log('REPLY-UPDATE-SERVER-RTC-ID 10');
                    window.mediaConnection.close();
                }

      
                setInterpreterProfile(dataChannelInterpreter);
                currentBahasa.rtcId = dataChannelInterpreter.server_rtc_id;
                currentBahasa.id_interpreter = dataChannelInterpreter.id_interpreter;
                
                setCurrentBahasa(currentBahasa);
                establishConnectionToInterpreter();
            }
        }
    };

    const onDataConnectionGetData = (data) => {
        console.log('fungsi onDataConnectionGetData.');
        if(data?.action === 'INTERPRETER_CHANGE_SERVER') {
            console.log(data.action + '!!');
            destroyServerConnection(data.action);
        }
    }

    const interpreterOnConnection = async (dataConnection) => {
        console.log('Peer data connection masuk coy, ' + dataConnection.peer);

        // setting volume mediastream interpreter
        const { mediaStreamSource, mediaStreamDestination } = generateMediastreamAudioContext();
        const call = myPeer.call(dataConnection.peer, mediaStreamDestination.stream);
        const currentTrackDeviceId = document.querySelector('#dropdown-audiotrack .active').id.replace(/audiotrack-/g, '');

        const firstTrackDeviceId = inputAudio[0] === undefined
            ? window.interpreterMediastream.getAudioTracks()[0].deviceId
            : inputAudio[0].deviceId;

        if(currentTrackDeviceId !== firstTrackDeviceId) {
            getMediaStream(currentTrackDeviceId).then( (mediaStream) => {
                const audioTrack = mediaStream.getAudioTracks()[0];
                replaceTrackPeerConnection(call.peerConnection, audioTrack);
            });
        }

        connectedUser.push({ dataConnection: dataConnection, call: call });
        setConnectedUser(connectedUser);
    }

    // manggil function callback yg butuh state terbaru
    useEffect( () => {
        console.log('fungsi myPeer callback.');
        if(!socket) return;
        socket.removeAllListeners('REPLY-UPDATE-SERVER-RTC-ID');
        socket.on('REPLY-UPDATE-SERVER-RTC-ID', onReplyUpdateServerRtc);
    }, [currentBahasa, myPeer]);

    // useEffect( () => {
    //     console.log('fungsi myPeer addEventListener.');
    //     if(!myPeer) return;

    //     document.addEventListener('visibilitychange', (e) => {
    //         if(meetingData?.role !== 'USER' || document.visibilityState !== 'visible' || !localStorage.getItem('last_dataChannelInterpreter') || !window.dataConnection) return;
    //         const last_dataChannelInterpreter = JSON.parse( localStorage.getItem('last_dataChannelInterpreter') );
            
    //         Swal.fire({ 
    //             title: 'Information' , 
    //             icon: 'info', 
    //             text: 'Your screen is sleep and interpreter have changes, click "OK" for reconnect to channel',
    //             allowOutsideClick: false, 
    //             allowEscapeKey: false

    //         }).then( () => {
    //             localStorage.removeItem('last_dataChannelInterpreter');
    //             setInterpreterProfile(last_dataChannelInterpreter);
    //             currentBahasa.rtcId = last_dataChannelInterpreter.server_rtc_id;
    //             currentBahasa.id_interpreter = last_dataChannelInterpreter.id_interpreter;
    //             setCurrentBahasa(currentBahasa);
    //             establishConnectionToInterpreter();
    //         });
    //     });
    // }, [myPeer]);

    useEffect( () => {
        console.log('fungsi myPeer interpreterOnConnection.');
        if(!myPeer) return;
        myPeer._events.connection = [];
        myPeer.on('connection', interpreterOnConnection);
    }, [myPeer, inputAudio]);


    const interpreterErrInputDevice = async () => {
        await alertError('Failed to call input audio device, please make sure you have granted your microphone access.');
        window.location.reload();
    }

    const setStatusBar = (x) => {
        if(!document.querySelector('.status-circle')) return;
        document.querySelector('.status-circle').setAttribute('class', 'status-circle bg-' + x);
    }

    const setMuteStatusBar = (isMuted) => {
        console.log('fungsi setMuteStatusBar.');
        if(isMuted) {
            document.getElementById('mute-status').classList.remove('d-none');
        } else {
            document.getElementById('mute-status').classList.add('d-none');
        }
    }

    const generateMediastreamAudioContext = () => {
        console.log('fungsi generateMediastreamAudioContext.');
        const mediaStreamSource = window.interpreterAudioContext.createMediaStreamSource(window.interpreterMediastream);
        const mediaStreamDestination = window.interpreterAudioContext.createMediaStreamDestination();

        mediaStreamSource.connect(window.interpreterGainNode);
        window.interpreterGainNode.connect(mediaStreamDestination);

        return { mediaStreamSource: mediaStreamSource, mediaStreamDestination: mediaStreamDestination };
    }

    const resetWindowTempState = () => {
        console.log('fungsi resetWindowTempState.');
        window.INTERPRETER_CHANGE_SERVER = undefined;
        window.FORCE_CLOSE_DATACONNECTION = undefined;
        window.INTERPRETER_HAS_MUTE = undefined;
    }

    const interpreterEnumerateDevices = async () => {
        console.log('fungsi interpreterEnumerateDevices.');
        if(!window.navigator.mediaDevices) {
            interpreterErrInputDevice();
            return false;
        }

        const devices = await window.navigator.mediaDevices.enumerateDevices();
        if(!devices || devices.length === 0) {
            interpreterErrInputDevice();
            return false;
        }

        const listTrack = getAudioInput(devices);
        if(listTrack.length === 0) {
            interpreterErrInputDevice();
            return false;
        }

        const mediaStream = await getMediaStream( listTrack[0].deviceId );
        if(!mediaStream) {
            interpreterErrInputDevice();
            return false;
        }

        window.interpreterAudioTrack = mediaStream.getAudioTracks()[0];
        window.interpreterMediastream = mediaStream;

        // setting volume mediastream interpreter
        window.interpreterAudioContext = new window.AudioContext();
        window.interpreterGainNode = window.interpreterAudioContext.createGain();

        enableChangeVolume();
        setInputAudio(listTrack);
        return true;
    };

    if(meetingIsError) {
        alertError( errFetchMessage(meetingError) ).then( () => {
            window.location.href = '/disconnect-meeting?fa=true';
        });
    }

    const delayPeerConnection = async (loader = true) => {
        if(loader) showLoader();

        await new Promise(resolve => {
            setTimeout(resolve, 1_500);
        });

        if(loader) hideLoader();
    }

    const setInterpreterProfile = (dataChannelInterpreter) => {
        document.getElementById('name-interpreter-text').innerText = dataChannelInterpreter?.nama_interpreter ?? null;
        document.querySelector('.photo-profile').setAttribute('src', dataChannelInterpreter?.foto_interpreter ?? null);
    }

    const clearDeviceId = (deviceId) => {
        return deviceId.replace(/[^a-zA-Z0-9]+/gi, '');
    }

    useEffect( () => {
        if(navigator.wakeLock) {
            navigator.wakeLock.request('screen').then( (lock) => {
                console.log('Preven sleep running.');
                
            }).catch( (err) => {
                console.error('Preven sleep error, not running');
                console.error(err);
            });

        } else {
            console.error('Preven sleep not available');
        }
    }, []);

    const destroyServerConnection = (from) => {
        console.log('fungsi destroyServerConnection.');
        if(meetingData?.role === 'USER') {
            if(window.audioPlayer) {
                window.audioPlayer.srcObject = null;
                window.audioPlayer.autoplay = false;
                window.audioPlayer.muted = true;
            }

            setStatusBar('danger');
            setMuteStatusBar(false);
            window.audioPlayer = undefined;

            if(from === 'INTERPRETER_CHANGE_SERVER' || from === 'INTERPRETER_HAS_MUTE') {
                setInterpreterProfile({
                    nama_interpreter: 'WAITING INTERPRETER',
                    foto_interpreter: '/assets/images/placeholder-avatar.png',
                });
            }

            if(from !== 'USER_CHANGE_SERVER' && from !== 'INTERPRETER_HAS_MUTE' && from !== 'INTERPRETER_CHANGE_SERVER') {
                setIsConnect(undefined);
                setCurrentBahasa({
                    idChannelBahasa: null,
                    rtcId: null,
                    id_interpreter: null,
                    nama_interpreter: null,
                    foto_interpreter: null,
                });
            }

        } else if(meetingData?.role === 'INTERPRETER') {
            console.log('EMIT UPDATE-SERVER-RTC-ID FROM destroyServerConnection!!');
            socket.emit('UPDATE-SERVER-RTC-ID', {
                _t: Cookies.get('_t'),
                idChannelBahasa: currentBahasa.idChannelBahasa,
                rtcId: null,
                isConnect: false,
            });

            setConnectedUser([]);
        }

        if(from !== undefined) window[from] = true;
        if(from === 'INTERPRETER_HAS_MUTE') {
            if(window.dataConnection) {
                window.dataConnection.close();
                window.dataConnection = undefined;
            }

            if(window.mediaConnection) {
                window.mediaConnection.close();
                window.mediaConnection = undefined;
            }

            setMuteStatusBar(true);
        } else {
            window.dataConnection = undefined;
        }

        if(meetingData?.role !== 'INTERPRETER') disableChangeVolume();
    }

    useEffect( () => {
        if(meetingLoaded) {
            if(meetingData.role === 'INTERPRETER') {
                getMediaStream()
                .then( () => {
                    interpreterEnumerateDevices();

                    if(window.navigator.mediaDevices) {
                        navigator.mediaDevices.addEventListener('devicechange', interpreterEnumerateDevices);
                    }

                }).catch(interpreterErrInputDevice);
            }

            let isShowAlertMeetingEnded = false;
            const momentMeeting = initMoment(meetingData.waktu_mulai);
            const intvalWaktuMeeting = setInterval( () => {
                const momentNow = moment(now());
                const durationHours = momentNow.diff(momentMeeting.valueOf(), 'hours');
                const durationMinutes = Math.floor( momentNow.diff(momentMeeting.valueOf(), 'minutes') % 60 );
                const durationSeconds = Math.floor( momentNow.diff(momentMeeting.valueOf(), 'seconds') % 60 );

                const counterHoursEl = document.getElementById('counter-hours');
                if(counterHoursEl) counterHoursEl.innerText = fixNumString(durationHours);

                const counterMinutesEl = document.getElementById('counter-minutes');
                if(counterMinutesEl) counterMinutesEl.innerText = fixNumString(durationMinutes);

                const counterSecondsEl = document.getElementById('counter-seconds');
                if(counterSecondsEl) counterSecondsEl.innerText = fixNumString(durationSeconds);

                // check apakah meeting sudah selesai
                const endMeetingTime = initMoment(meetingData.waktu_selesai).valueOf();
                const diffMeetingEnd = Math.floor( (endMeetingTime - momentNow.valueOf()) / 1000 );
                const alertMeetigEndsIn = Number(process.env.REACT_APP_ALERT_MEETING_ENDS_IN);

                if(diffMeetingEnd <= 0) { 
                    Swal.fire({ 
                        title: 'MEETING ENDED' , 
                        icon: 'warning' , 
                        text: 'This meeting has ended, thank you for being a participant.',
                        allowOutsideClick: false, 
                        allowEscapeKey: false

                    }).then( ()=> {
                        window.location.href = '/disconnect-meeting?fa=true';
                    });

                    destroyServerConnection();
                    clearInterval(intvalWaktuMeeting);

                } else if(diffMeetingEnd <= alertMeetigEndsIn && !isShowAlertMeetingEnded) { 
                    const meetingEndsIn = alertMeetigEndsIn > 60 ? Math.floor(alertMeetigEndsIn / 60) + ' minutes' : alertMeetigEndsIn + ' seconds';
                    Swal.fire({
                        title: 'INFORMATION',
                        icon: 'warning',
                        text: 'This meeting will end in ' + meetingEndsIn,
                    });

                    isShowAlertMeetingEnded = true;
                }
            }, 1000);

            window.INTVAL_WAKTU_MEETING = intvalWaktuMeeting;

            const socket = io(process.env.REACT_APP_WS_URL, {
                auth: { token: Cookies.get('_t') }
            });

            socket.on('connect', () => {
                console.log('Connected to socket server.');
                socket.emit('JOIN-MEETING-ROOM', { idMeeting: meetingData?.id_meeting });

                socket.on('UPDATE-MEETING-DATA', (dataMeeting) => {
                    console.log('UPDATE-MEETING-DATA!!');
                    dataMeeting.role = meetingData.role;
                    clearInterval(window.INTVAL_WAKTU_MEETING);
                    window.INTVAL_WAKTU_MEETING = undefined;
                    refreshMeetingData();
                });

                socket.on('REPLY-UPDATE-SERVER-RTC-ID', onReplyUpdateServerRtc);
                setSocket(socket);
            });

            socket.on('connect_error', (err) => {
                console.error('Connection error!');
                console.error(err);
            });
        }
    }, [meetingData]);

    useEffect( () => {
        console.log('fungsi window.Peer.');
        if(!window.Peer) return;
        const peer = new window.Peer(UUIDv4.generate(), { 
            debug: 0, 
            secure: true,
            config: { 
                iceServers: [
                    { 
                        urls: process.env.REACT_APP_RTC_SERVER_TURN_HOST,
                        username: process.env.REACT_APP_RTC_SERVER_TURN_USERNAME,
                        credential: process.env.REACT_APP_RTC_SERVER_TURN_PASSWORD 
                    },
                    { 
                        urls: process.env.REACT_APP_RTC_SERVER_STUN,
                    },
                ],
                sdpSemantics: 'unified-plan'
            } 
        });

        peer.on('open', (id) => {
            console.log('Peer connection open.. ' + id);
        });
        
        peer._events.connection = [];
        peer.on('connection', interpreterOnConnection);

        peer.on('close', () => {
            console.log('Peer connection close..');
            if(window.INTERPRETER_CHANGE_SERVER) {
                window.INTERPRETER_CHANGE_SERVER = undefined;
            }
        });

        setMyPeer(peer);
    }, [window.Peer]);

    const handleDisconnectBtn = async () => {
        const confirm = await alertConfirm('Are you sure you want to disconnect from this meeting?');
        if(confirm.isConfirmed) {
            if(myPeer !== undefined) destroyServerConnection();
            window.location.href = '/disconnect-meeting';
        }
    }

    const [changeChannelBahasa] = useChangeChannelBahasaMutation();
    const changeServerBahasa = async (idChannelBahasa, showAlert = true) => {
        console.log('fungsi changeServerBahasa.');
        if( currentBahasa.idChannelBahasa === idChannelBahasa ) return;
        const btn = document.getElementById('btn-language-' + idChannelBahasa);
        if(btn.hasAttribute('disabled')) return;

        if( showAlert && currentBahasa.idChannelBahasa !== null && meetingData?.role !== 'INTERPRETER' ) {
            const confirmSwitch = await alertConfirm('Are you sure you want to switch language channels?');
            if( !confirmSwitch.isConfirmed ) return;
        }

        localStorage.removeItem('last_dataChannelInterpreter');
        setStatusBar('danger');

        if(meetingData?.role === 'USER') setMuteStatusBar(true);
        if(meetingData?.role === 'USER' && window.MUTE_STATUS) muteAudio();

        if(meetingData?.role !== 'INTERPRETER') disableChangeVolume();
        if(meetingData?.role === 'USER' && window.dataConnection) {
            destroyServerConnection('INTERPRETER_HAS_MUTE');
        }

        showLoader();
        changeChannelBahasa({
            id_channel_bahasa: idChannelBahasa,
            prev_id_channel_bahasa: !window.MUTE_STATUS ? currentBahasa.idChannelBahasa : undefined,

        }).unwrap().then( async ({ data: dataChangeServer }) => {
            console.log('CHANGE_SERVER!');
            const { id_channel_bahasa, server_rtc_id } = dataChangeServer;
            let channel_interpreter = dataChangeServer.channel_interpreter;

            const x = () => {
                hideLoader();
                if(showAlert && meetingData?.role !== 'INTERPRETER') {
                    Swal.fire({
                        title: 'Yeay!',
                        icon: 'success',
                        text: 'Change language channel successfully.',
                    }).then( () => {
                        muteAudio(true);
                    });
                }

                if(meetingData.role === 'INTERPRETER') {
                    setInterpreterProfile(channel_interpreter);
                } else {
                    if(!channel_interpreter) {
                        channel_interpreter = {
                            nama_interpreter: 'WAITING INTERPRETER',
                            foto_interpreter: '/assets/images/placeholder-avatar.png',
                        };
                    } else {
                        setMuteStatusBar(false);
                    }

                    setInterpreterProfile(channel_interpreter);
                }

                setIsConnect(meetingData.role === 'INTERPRETER');
                setCurrentBahasa({
                    idChannelBahasa: id_channel_bahasa,
                    rtcId: meetingData.role === 'INTERPRETER' ? null : server_rtc_id,
                    id_interpreter: dataChangeServer?.channel_interpreter?.id_interpreter ?? null,
                    nama_interpreter: channel_interpreter?.nama_interpreter ?? null,
                    foto_interpreter: channel_interpreter?.foto_interpreter ?? null,
                });
            }

            if(meetingData?.role === 'INTERPRETER' && connectedUser.length > 0 && !window.MUTE_STATUS) {
                showLoader();

                await new Promise( (resolve) => {
                    connectedUser.forEach( (connectionUser, i) => {
                        connectionUser.dataConnection.send({
                            action: 'INTERPRETER_CHANGE_SERVER',
                            payload: id_channel_bahasa,
                        });

                        if( (i + 1) === connectedUser.length ) {
                            setTimeout(resolve, 500);
                        }
                    });

                }).then( () => {
                    hideLoader();
                    setConnectedUser([]);
                    destroyServerConnection('USER_CHANGE_SERVER');
                    x();
                });

            } else {
                x();
            }

        }).catch( (error) => {
            hideLoader();
            alertError( errFetchMessage(error) );
        });
    }

    const getMediaStream = async (deviceId) => {
        console.log('fungsi getMediaStream. = '+deviceId);
        return await navigator.mediaDevices.getUserMedia({
            audio: { deviceId: deviceId }
        });
    }

    const constraints = window.constraints = {
        audio: true,
        video: false
      };

    const enableChangeVolume = () => {
        console.log('fungsi enableChangeVolume.');
        if(!document.getElementById('formVolumeSpeaker')) return;
        document.getElementById('formVolumeSpeaker').removeAttribute('disabled');
    }

    const disableChangeVolume = () => {
        console.log('fungsi disableChangeVolume.');
        if(!document.getElementById('formVolumeSpeaker')) return;
        document.getElementById('formVolumeSpeaker').disabled = true;
    }

    const establishConnectionToInterpreter = async () => {
        console.log('fungsi establishConnectionToInterpreter.');
        if(!currentBahasa.rtcId) return;
        setStatusBar('warning');
        const conn = myPeer.connect(currentBahasa.rtcId);
        handleServerUser(conn, myPeer);

        setTimeout( () => {
            if((!conn || !conn.open) && !window.audioPlayer && !document.querySelector('.status-circle').classList.contains('bg-success')) {
             // setStatusBar('danger');
             //harusnya ada swall fire supaya refresh
                Swal.fire({ 
                title: 'Information' , 
                icon: 'info', 
                text: 'Connection Timeout',
                allowOutsideClick: false, 
                allowEscapeKey: false

            }).then( () => {
                localStorage.removeItem('last_dataChannelInterpreter');
                window.location.reload();
            });
            }
        }, 5000);
    }

    const getAudioInput = (devices) => {
        console.log('fungsi getAudioInput.');
        const inputDevices = [];
        devices.forEach( (device) => {
            const [kind] = device.kind.match(/(\w+)(input|output)/i);
            if (kind === "audioinput") {
                inputDevices.push(device);
            }
        });

        return inputDevices;
    }

    const handleServerUser = async (dataConnection) => {
        console.log('fungsi handleServerUser dataConnection = '+dataConnection);
        if(!dataConnection) {
            establishConnectionToInterpreter();
            return;
        }

        dataConnection.on('open', () => {
            console.log('Connected to interpreter.');
            resetWindowTempState();
            setStatusBar('success');
            setMuteStatusBar(false);
            setIsConnect(true);
        });

        myPeer.on('call', async (mediaConnection) => {
            console.log('Call Event');
            // const mediaStream = await getMediaStream();
            if(platform === 'Android' || platform === 'Windows'){
                console.log('Call Event Android / Windows');
                mediaConnection.answer();
            }else{
                console.log('Call Event IOS');
                 const mediaStream = await getMediaStream();
                 mediaConnection.answer(mediaStream);
            }

            mediaConnection.on('stream', (stream) => {
                console.log('STREAM EVENT !!');
                if(window.audioPlayer !== undefined) {
                    window.audioPlayer.srcObject = stream; 
                    return;
                }

                console.log('STREAM EVENT window.audioPlayer 2');

                const audio = new window.Audio();
                audio.srcObject = stream;
                audio.autoplay = true;

                window.audioPlayer = audio;
                enableChangeVolume();
            });

            mediaConnection.on('error', (err) => {
                console.log('Media connection error.');
            });

            console.log('STREAM EVENT window.audioPlayer 3');
            window.mediaConnection = mediaConnection;
            console.log('STREAM EVENT window.audioPlayer 4');
        });

        dataConnection.on('data', onDataConnectionGetData);

        dataConnection.on('close', () => {
            console.log('Data connection closed..');
            if(window.INTERPRETER_CHANGE_SERVER || window.FORCE_CLOSE_DATACONNECTION || window.INTERPRETER_HAS_MUTE) {
                resetWindowTempState();
                window.dataConnection = undefined;
                window.mediaConnection = undefined;
                return;
            }

            Swal.fire({
                title: 'INFORMATION',
                icon: 'warning',
                text: 'Connection with interpreter has been lost, please reselect language channel',
                showCancelButton: true,
                cancelButtonText: 'Call Whatsapp',
                focusConfirm: false,
                cancelButtonColor: '#57B657',

            }).then( ({ isConfirmed }) => {
                if(!isConfirmed) {
                    window.open(process.env.REACT_APP_LINK_WA);
                }
            });

            destroyServerConnection();
            setInterpreterProfile({
                nama_interpreter: 'CHOOSE LANGUAGE',
                foto_interpreter: '/assets/images/placeholder-avatar.png',
            });
            
        });

        window.dataConnection = dataConnection;
        window.FORCE_CLOSE_DATACONNECTION = undefined;
    }

    const replaceTrackPeerConnection = (peerConnection, audioTrack) => {
        console.log('fungsi replaceTrackPeerConnection.');
        peerConnection.getSenders()[0].replaceTrack(audioTrack).then( () => {
            console.log('replaceTrackPeerConnection!!');
        }).catch( () => {
            console.error('Error when replaceTrackPeerConnection!!');
        });
    }

    useEffect( () => {
        console.log('fungsi currentBahasa.');
        if(isConnect === undefined) return;

        // start connnection
        if(meetingData?.role === 'INTERPRETER') {
            // INTERPRETER
            muteAudio(false);

        } else {
            // USER
            if(currentBahasa.rtcId && !window.dataConnection) {
                establishConnectionToInterpreter();
            } else if(!currentBahasa.rtcId || !window.dataConnection || !window.dataConnection.open) {
                setStatusBar('danger');
            }
        }

    }, [currentBahasa]);

    const changeAudioInput = (deviceId) => {
        getMediaStream(deviceId).then( (mediaStream) => {
            const $this = document.querySelector(`#dropdown-audiotrack #audiotrack-${deviceId}`);
            const isActive = $this.classList.contains('active');
            if(isActive) return;

            window.interpreterMediastream = mediaStream;
            const { mediaStreamSource, mediaStreamDestination } = generateMediastreamAudioContext();

            const audioTrack = mediaStreamDestination.stream.getAudioTracks()[0];
            connectedUser.forEach( ({ call }) => {
                replaceTrackPeerConnection(call.peerConnection, audioTrack);
            });

            document.querySelector('#dropdown-audiotrack .active').classList.remove('active');
            $this.classList.add('active');
            window.interpreterAudioTrack = audioTrack;
        });
    }

    const changeVolumeSpeaker = (volume) => {
        if(meetingData?.role === 'INTERPRETER') {
            if(!window.interpreterGainNode) return;
            window.interpreterGainNode.gain.value = volume / 100;
        } else {
            if(!window.audioPlayer) return;
            window.audioPlayer.volume = volume / 100;
        }

        document.getElementById('volume-label').innerText = volume;
    }

    const muteAudio = async (isMuted) => {
        console.log('fungsi muteAudio.');
        const isClicked = isMuted === undefined;

        // jika user/interpreter klik mute tapi belum milih channel bahasa
        if(isClicked && !currentBahasa.idChannelBahasa) {
            alertError('You have not selected any language channels');
            return;
        }

        window.MUTE_STATUS = window.MUTE_STATUS ?? false;

        console.log('fungsi muteAudio isMuted = '+isMuted);

        if(isMuted === undefined) isMuted = !window.MUTE_STATUS;

        console.log('fungsi muteAudio window.MUTE_STATUS = '+window.MUTE_STATUS);

        if(meetingData?.role === 'INTERPRETER') {
            if(isClicked) {
                const confirmMute = await alertConfirm(
                    window.MUTE_STATUS 
                        ? 'Are you sure you want to unmute your microphone?' 
                        : 'Are you sure you want to mute your microphone?'
                );

                if(!confirmMute.isConfirmed) return;
            }

            if(!isMuted) {
                // update ke server, dengan rtc id terbaru
                console.log('EMIT UPDATE-SERVER-RTC-ID FROM muteAudio [unmuted]!!');
                socket.emit('UPDATE-SERVER-RTC-ID', {
                    _t: Cookies.get('_t'),
                    idChannelBahasa: currentBahasa.idChannelBahasa,
                    rtcId: myPeer.id,
                    isConnect: true,
                });

            } else {
                if(isClicked) {
                    // send update to user when interpreter is mute audio
                    console.log('EMIT UPDATE-SERVER-RTC-ID FROM muteAudio [muted]!!');
                    socket.emit('UPDATE-SERVER-RTC-ID', {
                        _t: Cookies.get('_t'),
                        idChannelBahasa: currentBahasa.idChannelBahasa,
                        rtcId: null,
                        isConnect: false,
                    });

                    destroyServerConnection('INTERPRETER_CHANGE_SERVER');
                    window['INTERPRETER_CHANGE_SERVER'] = undefined;
                }
            }

        } else {
            if(!window.audioPlayer) return;
            isMuted = !window.audioPlayer.muted;
            console.log('fungsi muteAudio window.audioPlayer 1 = '+isMuted);
            window.audioPlayer.muted = isMuted;
            console.log('fungsi muteAudio window.audioPlayer 2 = '+window.audioPlayer.muted);
        }

        let btnMuteImg;
        if(isMuted) {
            btnMuteImg = meetingData?.role === 'INTERPRETER' ? 'btn-mic-off.png' : 'btn-headset-off.png';
        } else {
            btnMuteImg = meetingData?.role === 'INTERPRETER' ? 'btn-mic.png' : 'btn-headset.png';
        }

        window.MUTE_STATUS = isMuted;
        console.log('fungsi muteAudio window.audioPlayer 3 = '+ window.MUTE_STATUS);
        document.getElementById('img-btn-mute').setAttribute('src', "/assets/images/" + btnMuteImg);
    }

    if(meetingIsLoading) return
    <Loader bypassDnone />
    const isShowInputAudio = meetingData?.role === 'USER' || inputAudio.length === 0;

    return (
        <>
            <Loader />

            <div className="bg-header fixed-top">
                <div className="d-flex align-items-center justify-content-between size-mobile">
                    <img src="/assets/images/logo-swara-2-crop.png" className="app-logo-swara-mobile ml-4"
                        alt="Logo Aplikasi Swara" width="160" />
                    <h6 className="font-weight-bold mb-0 text-light-blue mr-4">Hi, {NAMA}</h6>
                </div>
            </div>

            <div className="container">
                <div className="text-center">
                    <div className="photo-profile-group mt-5">
                        <img src="/assets/images/placeholder-avatar.png" className="photo-profile shadow" alt="" />
                        { meetingData?.role === 'USER' && <div class="status-circle bg-danger"></div> }
                    </div>
                    
                    <h6 className="text-dark-blue name font-weight-bold" id="name-interpreter">
                        <span id="name-interpreter-text">CHOOSE LANGUAGE</span>
                    </h6>

                    <p className="mb-0 mt-1 d-none" id="mute-status">[MUTED]</p>

                    <p className="h5 mt-3 text-light-blue">
                        <span id="counter-hours">00</span><span>:</span>
                        <span id="counter-minutes">00</span><span>:</span>
                        <span id="counter-seconds">00</span>
                    </p>
                </div>

                <div className="row px-2 px-sm-0 justify-content-center">
                    {
                        meetingData?.channel_bahasa && meetingData.channel_bahasa.map( (serverBahasa, indexSrvBahasa) => {
                        return (
                            <div className="col-6 col-md-4 col-lg-3 col-xl-3 text-center" key={indexSrvBahasa}>
                                <button className={`btn btn-sm bg-btn-language mt-5 w-100 ${
                                    currentBahasa.idChannelBahasa===serverBahasa.id_channel_bahasa ? 'active' : '' }`}
                                    id={"btn-language-" + serverBahasa.id_channel_bahasa} onClick={ ()=>
                                    changeServerBahasa(serverBahasa.id_channel_bahasa) }>
                                    <img src={ serverBahasa.bahasa.gambar_bendera }
                                        className="shadow mt-2 rounded img-flag text-white"
                                        alt={serverBahasa.bahasa.nama_bahasa} />
                                    <h3 className="text-dark name-language mb-2 font-weight-bold">
                                        {serverBahasa.bahasa.nama_bahasa}</h3>
                                </button>
                            </div>
                            )
                        })
                    }
                </div>
            </div>

            <footer className="footer bg-footer mt-5">
                <div className="row row-footer align-items-center">
                    <div className={`text-center mt-2 mb-4 mb-md-0 ${ isShowInputAudio ? 'd-none-mic-mobile' : '' }`}>
                        <div className="btn-group dropup">
                            {
                                inputAudio.length === 0 &&
                                <>
                                    <button type="button" className={"btn btn-light dropdown-toggle " + (meetingData?.role === 'USER' ? 'd-none-mic-desktop' : '')}>
                                        <i className="fas fa-microphone mr-1"></i> <span className="mr-1">Microphone</span>
                                    </button>
                                </>
                            }

                            {
                                inputAudio.length > 0 &&
                                <>
                                    <button type="button" className={"btn btn-light dropdown-toggle " + (meetingData?.role === 'USER' ? 'd-none-mic-desktop' : '')} data-toggle="dropdown"
                                        aria-expanded="false">
                                        <i className="fas fa-microphone mr-1"></i> <span
                                            className="mr-1">Microphone</span>
                                    </button>
                                </>
                            }

                            <div className="dropdown-menu text-white py-0 overflow-hidden" id="dropdown-audiotrack">
                                {
                                    inputAudio.map( (audioTrack, indexAudio) => {
                                        return (
                                            <a className={`dropdown-item px-3 py-2 ${ indexAudio==0 ? 'active' : '' }`}
                                                key={indexAudio} id={`audiotrack-${ clearDeviceId(audioTrack.deviceId) }`} onClick={ ()=>
                                                changeAudioInput( clearDeviceId(audioTrack.deviceId) ) }>
                                                {audioTrack.label}
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-meeting-control mb-4 mb-md-0 mt-1">
                        <a onClick={ ()=> handleDisconnectBtn() } className="btn btn-dark-red mr-2">
                            <img src="/assets/images/btn-disconnect-2.png" className="btn-disconnect" width="35"
                                alt="Button Disconnect" />
                        </a>

                        <button className={"btn btn-black " + (meetingData?.role === 'INTERPRETER' ? 'mr-2' : '')} id="
                            btn-mute" onClick={ ()=> muteAudio() }>
                            <img src={"/assets/images/" + (meetingData?.role==='INTERPRETER' ? 'btn-mic-off.png'
                                : 'btn-headset.png' )} id="img-btn-mute" className="btn-disconnect" width="35"
                                alt="Button Mute" />
                        </button>
                    </div>

                    <div className="d-block mb-3">
                        <form>
                            <label htmlFor="formVolumeSpeaker" className="text-white">{
                                (meetingData?.role === 'INTERPRETER' ? 'Volume Microphone' : 'Volume Speaker') }
                            </label>
                            <div className="range-wrap form-group form-group-volume mb-0">
                                <input type="range" className="range form-control-range" id="formVolumeSpeaker" 
                                    defaultValue="100" onChange={ (e)=> changeVolumeSpeaker(e.target.value) } disabled />
                                <output className="bubble mt-1" id="volume-label">100</output>
                            </div>
                        </form>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default MeetingRoom;