import { useEffect } from "react";
import { useDisconnectMutation } from "../features/meetingApiSlice.ts";
import Loader from "./Loader";
import { alertError, errFetchMessage } from "../helper";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlert } from "../features/alertSlice.js";

function DisconnectMeeting() {
    const [
        disconnect,
        { isError, isSuccess, error, data }
    ] = useDisconnectMutation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const forceAlert = searchParams.get('fa') ?? '';

    useEffect( () => {
        if(isError) alertError( errFetchMessage(error) );

        if(isSuccess) {
            window.localStorage.removeItem('NAMA');
            if(forceAlert === '' || forceAlert !== 'true') dispatch( setAlert({icon: 'success', message: data.message}) );
            window.location.href = 'https://forms.gle/xkDJ3cVxXypgm9eA6';
            // navigate('/thankyou');
        }

    }, [isError, isSuccess]);

    useEffect( () => {
        disconnect();
    }, []);

    return (
        <> <Loader bypassDnone /> </>
    );
}

export default DisconnectMeeting;