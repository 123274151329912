function AuthSteps({ steps, activeStep }) {
    return (
        <>
            <div className="row justify-content-center align-items-center pt-5 card-row">
                {
                    steps.map( (step, indexStep) => {
                        return (
                            <div key={indexStep} className={`card card-width mt-2 text-center mr-3 shadow-sm strip ` + (activeStep >= indexStep ? 'btn-light-blue' : '')}>
                                <div className="card-body w-100">
                                    { step }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default AuthSteps;